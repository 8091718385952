export const base_url = `${window.location.origin}/backend/`
// export const base_url = `http://193.239.237.144:8002/`;
// export const base_url = `http://193.239.237.147:8000/`;
// export const base_url = `http://193.239.237.149:8000/`;
// export const base_url = `http://217.145.69.50:8000/`;
// export const base_url = `http://217.145.69.58:8000/`;


// export const base_url = `http://217.145.69.67:8000/`;
// export const base_url = `https://soptools.tradestreet.in/backend/`;
// export const base_url = `https://app.thebuildstudy.com/backend/`;
// export const base_url = `https://tools.tradestreet.in/backend/`;
// export const base_url = `https://tools.nextbrand.tech/backend/`;
// export const base_url = `https://tools.scalpingalgo.in//backend/`;
// export const base_url = `https://tools.easytrades.in//backend/`;
// if superAdmin login then change the url only for it
// export const superAdmin_base_url = `${window.location.origin}/superbackend/`

// export const superAdmin_base_url = `https://soptools.tradestreet.in/superbackend/`;
export const superAdmin_base_url = `http://217.145.69.58:8001/`;



// export const base_url = `http://217.145.69.50:8000/`;


